var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{attrs:{"flat":""}},[_c('v-card',{staticClass:"ma-1",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"px-10"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"title font-weight-bold"},[_vm._v("Fixture Details")])])],1),_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1 mb-0"},[_vm._v("Project Name *")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fieldDescriptions.project_name)}})])],1),_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"quoteReference","type":"text","autocomplete":"off","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"project-name","data-cy":"rfq-quote-project-name","rules":_vm.hybridRules({
                  required: true,
                  formValue: _vm.formQuoteReference,
                  vuexValue: _vm.quote.project_name,
                })},on:{"input":function($event){return _vm.updateUIState({
                  key: 'project_name',
                  value: _vm.formQuoteReference,
                })}},model:{value:(_vm.formQuoteReference),callback:function ($$v) {_vm.formQuoteReference=$$v},expression:"formQuoteReference"}})],1)],1),_c('v-row',{staticClass:"pa-0 mt-5 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Number of unique DUTs in Fixture *")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fieldDescriptions.number_of_unique_duts)}})])],1),_c('v-row',{staticClass:"mb-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"subtitle-1",attrs:{"items":[1, 2],"name":"quoteNumberOfDuts","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"number-of-duts","data-cy":"rfq-quote-number-of-duts","rules":_vm.hybridRules({
                  required: true,
                  formValue: _vm.formQuoteNumberOfDuts,
                  vuexValue: _vm.quote.number_of_unique_duts,
                })},on:{"change":function($event){return _vm.updateNumberOfDuts({
                  key: 'number_of_unique_duts',
                  value: _vm.formQuoteNumberOfDuts,
                })}},model:{value:(_vm.formQuoteNumberOfDuts),callback:function ($$v) {_vm.formQuoteNumberOfDuts=$$v},expression:"formQuoteNumberOfDuts"}})],1)],1),_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("First DUT Name *")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Pick a name to reference this device. We'll get specific revision and assembly numbers later.")])])],1),_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"quoteFirstDutName","type":"text","autocomplete":"off","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"dut-name-1","data-cy":"rfq-quote-dut-name-1","rules":_vm.hybridRules({
                  required: true,
                  formValue: _vm.formQuoteDutName1,
                  vuexValue: _vm.dutDetails[0].dut_name,
                })},on:{"input":function($event){return _vm.updateUIState({
                  key: 'dut_name_1',
                  value: _vm.formQuoteDutName1,
                })}},model:{value:(_vm.formQuoteDutName1),callback:function ($$v) {_vm.formQuoteDutName1=$$v},expression:"formQuoteDutName1"}}),(
                _vm.dutDetails.length > 0 && Object.keys(_vm.dutDetails[0]).length > 1
              )?_c('v-btn',{staticClass:"mb-5",attrs:{"small":"","color":"warning","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin},on:{"click":function($event){return _vm.showDialog({
                  id: 0,
                  nextAction: 'delete',
                  message:
                    'Are you sure? Removing this DUT from the Quote will delete its associated data.',
                })}}},[_vm._v("Remove this DUT from Quote")]):_vm._e()],1)],1),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.formQuoteNumberOfDuts > 1),expression:"this.formQuoteNumberOfDuts > 1"}],key:"1"},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Second DUT Name *")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Pick a name to reference this device. We'll get specific revision and assembly numbers later.")])])],1),_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"quoteSecondDutName","type":"text","autocomplete":"off","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"dut-name-2","data-cy":"rfq-quote-dut-name-2","rules":_vm.hybridRules({
                      required: _vm.formQuoteNumberOfDuts > 1,
                      formValue: _vm.formQuoteDutName2,
                      vuexValue:
                        _vm.dutDetails.length > 1 ? _vm.dutDetails[1].dut_name : null,
                    })},on:{"input":function($event){return _vm.updateUIState({
                      key: 'dut_name_2',
                      value: _vm.formQuoteDutName2,
                    })}},model:{value:(_vm.formQuoteDutName2),callback:function ($$v) {_vm.formQuoteDutName2=$$v},expression:"formQuoteDutName2"}}),(
                    _vm.dutDetails.length > 1 &&
                      Object.keys(_vm.dutDetails[1]).length > 1
                  )?_c('v-btn',{staticClass:"mb-5",attrs:{"small":"","color":"warning","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin},on:{"click":function($event){return _vm.showDialog({
                      id: 1,
                      nextAction: 'delete',
                      message:
                        'Are you sure? Removing this DUT from the Quote will delete its associated data.',
                    })}}},[_vm._v("Remove this DUT from Quote")]):_vm._e()],1)],1)],1)]),_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Project Type *")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fieldDescriptions.project_type)}}),(_vm.formQuoteNumberOfDuts > 1)?_c('v-alert',{staticClass:"ml-n1 my-0 subtitle-2",attrs:{"color":"warning","dense":"","outlined":"","type":"info"}},[_vm._v("Only Production Fixtures support multiple DUTs.")]):_vm._e()],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{staticClass:"subtitle-1",attrs:{"items":_vm.projectTypeList,"item-disabled":"disable","name":"quoteProjectType","item-text":"description","item-value":"pk","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"project-type","data-cy":"rfq-quote-project-type","rules":_vm.hybridRules({
                  required: true,
                  formValue: _vm.formQuoteProjectType,
                  vuexValue: _vm.quote.project_type,
                })},on:{"change":function($event){return _vm.updateUIState({
                  key: 'project_type',
                  value: _vm.formQuoteProjectType,
                })}},model:{value:(_vm.formQuoteProjectType),callback:function ($$v) {_vm.formQuoteProjectType=$$v},expression:"formQuoteProjectType"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"ml-10 my-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-btn',{attrs:{"x-large":"","color":"secondary","data-cy":"rfq-quote-start-quote-button"},on:{"click":_vm.onSave}},[_vm._v(_vm._s(_vm.editMode || Object.keys(_vm.quote).length > 0 ? (!_vm.inputValid ? "Save and " : "") + "Continue" : "Start Quote"))])],1)],1)],1)],1)],1),_c('base-bottom-dialog',{attrs:{"dialog":_vm.bottomDialog,"message":_vm.confirmMessage,"action":_vm.confirmAction},on:{"delete":function($event){return _vm.removeDut(_vm.selected)},"donothing":_vm.stopDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }