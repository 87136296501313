<template>
  <v-sheet flat>
    <v-card flat class="ma-1">
      <v-card-text class="px-10">
        <v-form lazy-validation ref="form">
          <v-row dense class="my-2">
            <v-col cols="12">
              <span class="title font-weight-bold">Fixture Details</span>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 ma-0">
            <v-col cols="12">
              <span class="subtitle-1 mb-0">Project Name *</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12">
              <span v-html="fieldDescriptions.project_name"></span>
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" md="6">
              <v-text-field
                name="quoteReference"
                type="text"
                autocomplete="off"
                v-model="formQuoteReference"
                outlined
                dense
                class="subtitle-1"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="project-name"
                data-cy="rfq-quote-project-name"
                @input="
                  updateUIState({
                    key: 'project_name',
                    value: formQuoteReference,
                  })
                "
                :rules="
                  hybridRules({
                    required: true,
                    formValue: formQuoteReference,
                    vuexValue: quote.project_name,
                  })
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 mt-5 mx-0">
            <v-col cols="12">
              <span class="subtitle-1">Number of unique DUTs in Fixture *</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span v-html="fieldDescriptions.number_of_unique_duts"></span>
            </v-col>
          </v-row>
          <v-row dense class="mb-5">
            <v-col cols="12" md="6">
              <v-select
                :items="[1, 2]"
                name="quoteNumberOfDuts"
                v-model="formQuoteNumberOfDuts"
                outlined
                dense
                class="subtitle-1"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="number-of-duts"
                data-cy="rfq-quote-number-of-duts"
                @change="
                  updateNumberOfDuts({
                    key: 'number_of_unique_duts',
                    value: formQuoteNumberOfDuts,
                  })
                "
                :rules="
                  hybridRules({
                    required: true,
                    formValue: formQuoteNumberOfDuts,
                    vuexValue: quote.number_of_unique_duts,
                  })
                "
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 ma-0">
            <v-col cols="12">
              <span class="subtitle-1">First DUT Name *</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12">
              <span
                >Pick a name to reference this device. We'll get specific
                revision and assembly numbers later.</span
              >
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" md="6">
              <v-text-field
                name="quoteFirstDutName"
                type="text"
                v-model="formQuoteDutName1"
                autocomplete="off"
                outlined
                dense
                class="subtitle-1"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="dut-name-1"
                data-cy="rfq-quote-dut-name-1"
                @input="
                  updateUIState({
                    key: 'dut_name_1',
                    value: formQuoteDutName1,
                  })
                "
                :rules="
                  hybridRules({
                    required: true,
                    formValue: formQuoteDutName1,
                    vuexValue: dutDetails[0].dut_name,
                  })
                "
              >
              </v-text-field>
              <v-btn
                v-if="
                  dutDetails.length > 0 && Object.keys(dutDetails[0]).length > 1
                "
                small
                color="warning"
                class="mb-5"
                :disabled="!isQuoteIncomplete && !isAdmin"
                @click="
                  showDialog({
                    id: 0,
                    nextAction: 'delete',
                    message:
                      'Are you sure? Removing this DUT from the Quote will delete its associated data.',
                  })
                "
                >Remove this DUT from Quote</v-btn
              >
            </v-col>
          </v-row>
          <transition name="slide">
            <div v-show="this.formQuoteNumberOfDuts > 1" key="1">
              <v-row dense class="pa-0 ma-0">
                <v-col cols="12">
                  <span class="subtitle-1">Second DUT Name *</span>
                </v-col>
              </v-row>
              <v-row dense class="ma-0 pa-0">
                <v-col cols="12">
                  <span
                    >Pick a name to reference this device. We'll get specific
                    revision and assembly numbers later.</span
                  >
                </v-col>
              </v-row>
              <v-row dense class="my-2">
                <v-col cols="12" md="6">
                  <v-text-field
                    name="quoteSecondDutName"
                    type="text"
                    v-model="formQuoteDutName2"
                    autocomplete="off"
                    outlined
                    dense
                    class="subtitle-1"
                    :disabled="!isQuoteIncomplete && !isAdmin"
                    data-test="dut-name-2"
                    data-cy="rfq-quote-dut-name-2"
                    @input="
                      updateUIState({
                        key: 'dut_name_2',
                        value: formQuoteDutName2,
                      })
                    "
                    :rules="
                      hybridRules({
                        required: formQuoteNumberOfDuts > 1,
                        formValue: formQuoteDutName2,
                        vuexValue:
                          dutDetails.length > 1 ? dutDetails[1].dut_name : null,
                      })
                    "
                  >
                  </v-text-field>
                  <v-btn
                    v-if="
                      dutDetails.length > 1 &&
                        Object.keys(dutDetails[1]).length > 1
                    "
                    small
                    color="warning"
                    class="mb-5"
                    :disabled="!isQuoteIncomplete && !isAdmin"
                    @click="
                      showDialog({
                        id: 1,
                        nextAction: 'delete',
                        message:
                          'Are you sure? Removing this DUT from the Quote will delete its associated data.',
                      })
                    "
                    >Remove this DUT from Quote</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </transition>
          <v-row dense class="pa-0 ma-0">
            <v-col cols="12">
              <span class="subtitle-1">Project Type *</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span v-html="fieldDescriptions.project_type"></span>
              <v-alert
                v-if="formQuoteNumberOfDuts > 1"
                color="warning"
                dense
                outlined
                type="info"
                class="ml-n1 my-0 subtitle-2"
                >Only Production Fixtures support multiple DUTs.</v-alert
              >
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-autocomplete
                :items="projectTypeList"
                item-disabled="disable"
                name="quoteProjectType"
                item-text="description"
                item-value="pk"
                v-model="formQuoteProjectType"
                outlined
                dense
                class="subtitle-1"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="project-type"
                data-cy="rfq-quote-project-type"
                @change="
                  updateUIState({
                    key: 'project_type',
                    value: formQuoteProjectType,
                  })
                "
                :rules="
                  hybridRules({
                    required: true,
                    formValue: formQuoteProjectType,
                    vuexValue: quote.project_type,
                  })
                "
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row dense class="ml-10 my-5">
          <v-col cols="12" md="6">
            <v-row>
              <v-btn x-large color="secondary" @click="onSave" data-cy="rfq-quote-start-quote-button">{{
                editMode || Object.keys(quote).length > 0
                  ? (!inputValid ? "Save and " : "") + "Continue"
                  : "Start Quote"
              }}</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <base-bottom-dialog
      :dialog="bottomDialog"
      :message="confirmMessage"
      :action="confirmAction"
      @delete="removeDut(selected)"
      @donothing="stopDialog"
    />
  </v-sheet>
</template>
<script>
import BottomDialog from "@/mixins/BottomDialog";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "QuoteSetupPage",
  mixins: [BottomDialog],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
      default: null,
    },
    quoteUser: {
      type: Object,
      default: null,
    },
    projectType: {
      type: Array,
      default: null,
    },
    fieldDescriptions: {
      type: Object,
      default: null,
    },
    dutDetails: {
      type: Array,
      default: null,
    },
    inputValid: {
      type: Boolean,
      default: false,
    },
    previousValid: {
      type: Boolean,
      default: false,
    },
    isQuoteIncomplete: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DEV_FIXTURE_DEFAULT_VOLUME: 500,
      PROD_FIXTURE_DEFAULT_VOLUME: 10000,
      DEFAULT_FIXTURE_DEV: {
        pk: 1,
        description: "DEV260 Development Fixture",
      },
      DEFAULT_FIXTURE_PROD: {
        pk: 3,
        description: "Ingun MA2111 Fixture Assembly",
      },
      DEFAULT_CONNECTION_TYPE: {
        pk: 4,
        description: "Wire-Wrap",
      },
      DEFAULT_TIP_STYPE: {
        pk: 3,
        description: "Spear",
      },
      DEFAULT_FD_VERSION: 1,
      DEFAULT_PCB_THICKNESS: 1.6,
      formQuoteReference:
        this.quote && this.editMode ? this.quote.project_name : null,
      formQuoteNumberOfDuts:
        this.quote && this.editMode ? this.quote.number_of_unique_duts : 1,
      formQuoteDutName1: this.dutDetails ? this.dutDetails[0].dut_name : null,
      formQuoteDutName2: this.dutDetails ? this.dutDetails[1].dut_name : null,
      formQuoteProjectType:
        this.quote && this.editMode ? this.quote.project_type : null,
      rules: {
        requireCheck: [(v) => !!v || "Input is required"],
      },
    };
  },
  computed: {
    projectTypeList() {
      return this.projectType.map((element) => ({
        pk: element.pk,
        description: element.description,
        disable: element.pk == 1 && this.formQuoteNumberOfDuts > 1,
      }));
    },
    billingAddress() {
      return this.$store.getters["ui/billingAddress"];
    },
    shippingAddress() {
      return this.$store.getters["ui/shippingAddress"];
    },
    shippingSameAsBilling() {
      return this.$store.getters["ui/shippingSameAsBilling"];
    },
  },
  methods: {
    ...mapActions({
      saveQuote: "quotes/saveQuote",
      getQuote: "quotes/getQuote",
      saveQuoteAddress: "quotes/saveQuoteAddress",
      deleteQuoteAddress: "quotes/deleteQuoteAddress",
      saveDutDetails: "quotes/saveDutDetail",
      getDutDetails: "quotes/getDutDetails",
      removeDutDetail: "quotes/deleteDutDetail",
      postProject: "projects/saveProject",
      removeProject: "projects/deleteProject",
      postFixtureDesign: "fixturedesign/saveFixtureDesign",
      saveDesignFilePackage: "fixturedesign/postDesignFilePackage",
      createDutConfig: "dutconfigs/saveDutConfigs",
      toggleLoading: "ui/loading",
    }),
    ...mapMutations({
      setUser: "projects/SET_USER",
    }),
    hybridRules({ required }) {
      const rules = [];
      if (required) {
        const rule = (v) => !!v || "Input is required";
        rules.push(rule);
      }
      return rules;
    },
    async onSaveQuoteData({ key, value }) {
      let actionPayload = {
        editMode: this.editMode || Object.keys(this.quote).length > 0,
        id:
          this.editMode || Object.keys(this.quote).length > 0
            ? this.quote.id
            : null,
        data: {
          user: this.quoteUser.pk,
          company:
            this.quoteUser.groups.length > 0
              ? this.quoteUser.groups[0].id
              : null,
          [key]: value,
        },
      };
      if (key == "number_of_unique_duts") {
        if (value > 1) {
          if (this.quote.project_type == 1) {
            actionPayload.data.project_type = this.formQuoteProjectType;
            actionPayload.data.project_timeline = this.formQuoteProjectType + 1;
            this.updateUIState({
              key: "project_timeline",
              value: this.formQuoteProjectType + 1,
            });
          }
          if (this.quote.panelized_fixture) {
            actionPayload.data.panelized_fixture = false;
            actionPayload.data.duts_in_panel = null;
            actionPayload.data.duts_per_row = null;
            actionPayload.data.duts_per_column = null;
            actionPayload.data.panel_details = "";
          }
        } else {
          this.removeDut(1);
        }
      }
      if (key == "project_type") {
        actionPayload.data.project_timeline = this.formQuoteProjectType + 1;
        this.updateUIState({
          key: "project_timeline",
          value: this.formQuoteProjectType + 1,
        });
      }

      try {
        this.toggleLoading("Saving");
        await this.saveQuote(actionPayload);
        await this.getDutDetails({ params: { quote_id: this.quote.id } });
        (key == "number_of_unique_duts" || key == "project_type") &&
          this.$emit("updateTimeline", this.quote.project_timeline);
        key == "number_of_unique_duts" &&
          value > 1 &&
          this.$emit("updatePanelize", this.quote.panelized_fixture);
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async onSaveDutData({ index, key, value }) {
      this.$emit("onSaveDutData", {
        id:
          Object.keys(this.dutDetails[index]).length > 1
            ? this.dutDetails[index].id
            : null,
        key,
        value,
        index,
      });
    },
    async onSave() {
      if (!this.$refs.form.validate()) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "Please fill up all required fields."
        );
        return;
      }

      if (this.inputValid && Object.keys(this.quote).length > 0) {
        this.onNextTab();
        return;
      }
      try {
        this.toggleLoading("Saving Quote Address");
        const billingAddressPayload = {
          editMode: this.editMode,
          id:
            this.editMode ||
            this.quote.billing_address
              ? this.quote.billing_address
              : null,
          data: this.billingAddress,
          isBilling: true,
        };
        const resBillingAddress = await this.saveQuoteAddress(
          billingAddressPayload
        );
        console.log("FROM SAVING", resBillingAddress);
        let resShippingAddress = null;
        if (this.shippingSameAsBilling) {
          if (
            this.editMode &&
            this.quote.billing_address != this.quote.shipping_address
          ) {
            await this.deleteQuoteAddress(this.quote.shipping_address);
          }
        } else {
          const shippingAddressPayload = {
            editMode:
              this.editMode ||
              this.quote.billing_address != this.quote.shipping_address,
            id:
              this.editMode ||
              this.quote.shipping_address
                ? this.quote.shipping_address
                : null,
            data: this.shippingAddress,
            isBilling: false,
          };
          resShippingAddress = await this.saveQuoteAddress(
            shippingAddressPayload
          );
          console.log("Shipping Address Posted", resShippingAddress);
        }
        this.toggleLoading();
        const editing = Object.keys(this.quote).length > 0;
        const actionPayload = {
          editMode: this.editMode || Object.keys(this.quote).length > 0,
          id:
            this.editMode || Object.keys(this.quote).length > 0
              ? this.quote.id
              : null,
          data: {
            user: this.quoteUser.pk,
            company:
              this.quoteUser.groups.length > 0
                ? this.quoteUser.groups[0].id
                : null,
            project_name: this.formQuoteReference,
            number_of_unique_duts: this.formQuoteNumberOfDuts,
            project_type: this.formQuoteProjectType,
            project_timeline: this.formQuoteProjectType + 1,
            estimated_volumes:
              this.formQuoteProjectType == 1
                ? this.DEV_FIXTURE_DEFAULT_VOLUME
                : this.PROD_FIXTURE_DEFAULT_VOLUME,
            panelized_fixture:
              this.quote.panelized_fixture && this.formQuoteNumberOfDuts > 1
                ? false
                : this.quote.panelized_fixture,
            billing_address: resBillingAddress.id,
            shipping_address: !this.shippingSameAsBilling
              ? resShippingAddress.id
              : resBillingAddress.id,
            shipping_same_as_billing: this.shippingSameAsBilling,
          },
        };
        this.toggleLoading("Saving Quote");
        const response = await this.saveQuote(actionPayload);
        this.updateUIState({
          key: "project_timeline",
          value: this.formQuoteProjectType + 1,
        });
        this.updateUIState({
          key: "estimated_volumes",
          value:
            this.formQuoteProjectType == 1
              ? this.DEV_FIXTURE_DEFAULT_VOLUME
              : this.PROD_FIXTURE_DEFAULT_VOLUME,
        });
        this.updateUIState({
          key: "panelized_fixture",
          value: this.quote.panelized_fixture,
        });

        const firstDutPayload = {
          editMode: Object.keys(this.dutDetails[0]).length > 1,
          id:
            Object.keys(this.dutDetails[0]).length > 1
              ? this.dutDetails[0].id
              : null,
          data: {
            user: this.quoteUser.pk,
            quote: response.id,
            dut_name: this.formQuoteDutName1,
          },
        };

        let response_dd1 = null;

        if (this.formQuoteDutName1 != this.dutDetails[0].dut_name) {
          response_dd1 = await this.saveDutDetails(firstDutPayload);
        }
        this.formQuoteNumberOfDuts == 1 &&
          this.dutDetails.length > 1 &&
          Object.keys(this.dutDetails[0]).length > 1 &&
          Object.keys(this.dutDetails[1]).length > 1 &&
          this.removeDut(1);

        let response_dd2 = null;

        if (this.formQuoteNumberOfDuts > 1) {
          const secondDutPayload = {
            editMode:
              this.dutDetails.length > 1 &&
              Object.keys(this.dutDetails[1]).length > 1,
            id:
              this.dutDetails.length > 1 &&
              Object.keys(this.dutDetails[1]).length > 1
                ? this.dutDetails[1].id
                : null,
            data: {
              user: this.quoteUser.pk,
              quote: response.id,
              dut_name: this.formQuoteDutName2,
            },
          };
          response_dd2 = await this.saveDutDetails(secondDutPayload);
        }
        await this.getDutDetails({ params: { quote_id: response.id } });
        this.$emit("updateTimeline", {
          key: "formQuoteTimeline",
          payload: this.quote.project_timeline,
        });
        this.$emit("updateTimeline", {
          key: "formQuoteEstimatedVolume",
          payload: this.quote.estimated_volumes,
        });
        this.$emit("updatePanelize", this.quote.panelized_fixture);
        this.toggleLoading();

        if (!this.editMode && !editing) {
          const fdPayload = {
            project_name: this.formQuoteReference,
            user: this.quoteUser.pk,
            fixture_config: this.formQuoteProjectType == 1 ? this.DEFAULT_FIXTURE_DEV.pk : this.DEFAULT_FIXTURE_PROD.pk,
            connection_type: this.DEFAULT_CONNECTION_TYPE.pk,
            tip_style: this.DEFAULT_TIP_STYPE.pk,
            version: this.DEFAULT_FD_VERSION,
            quote: response.id,
            dut_details: {
              id_dut1: response_dd1 ? response_dd1.id : null,
              id_dut2: response_dd2 ? response_dd2.id : null,
            },
          };
          await this.postFD(fdPayload);
          await this.getQuote(response.id);
        }
        this.onNextTab();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async removeDut(index) {
      this.bottomDialog && this.stopDialog();
      try {
        this.toggleLoading("Removing DUT");
        await this.removeDutDetail({ index, id: this.dutDetails[index].id });
        this[`formQuoteDutName${index + 1}`] = null;
        this.updateUIState({ key: [`dut_name_${index + 1}`], value: null });
        this.selected = null;
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      await this.updateApiNumberOfDuts();
    },
    async updateApiNumberOfDuts() {
      if (this.formQuoteNumberOfDuts <= 1) return;

      this.formQuoteNumberOfDuts = 1;
      await this.saveNumberOfDuts({
        key: "number_of_unique_duts",
        value: this.formQuoteNumberOfDuts,
      });
      this.formQuoteDutName1 = this.dutDetails[0].dut_name;
      this.updateUIState({
        key: "number_of_unique_duts",
        value: this.formQuoteNumberOfDuts,
      });
    },
    async saveNumberOfDuts({ key, value }) {
      const actionPayload = {
        editMode: true,
        id: this.quote.id,
        data: {
          [key]: value,
        },
      };

      try {
        this.toggleLoading("Saving Number of DUTs");
        await this.saveQuote(actionPayload);
        await this.getDutDetails({ params: { quote_id: this.quote.id } });
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    onNextTab() {
      this.$emit("nextTab");
    },
    onPreviousTab() {
      this.$emit("previousTab");
    },
    updateUIState(payload) {
      this.$emit("onUpdateUIState", payload);
    },
    updateNumberOfDuts(payload) {
      this.updateUIState(payload);
      this.updateProjectType();
    },
    updateProjectType() {
      if (this.formQuoteNumberOfDuts <= 1) return;
      this.formQuoteProjectType = 2;
      this.updateUIState({
        key: "project_type",
        value: this.formQuoteProjectType,
      });
    },
    updateDutName({ index, value }) {
      this[`formQuoteDutName${index + 1}`] = value;
    },
    async postFD({
      project_name,
      user,
      fixture_config,
      connection_type,
      tip_style,
      version,
      quote,
      dut_details,
    }) {
      let projectObj;
      try {
        //Post to Project
        const projectPayload = {
          project_name,
          user,
        };
        projectObj = await this.postProject(projectPayload);
        //Post to FD
        let dutData = new FormData();
        dutData.append("project", projectObj.pk);
        dutData.append("user", projectObj.user);
        dutData.append("fixture_config", fixture_config);
        dutData.append("receptacle_connection_preference", connection_type);
        dutData.append("tip_style_preference", tip_style);
        dutData.append("version", version);
        dutData.append("quote", quote);
        const response = await this.postFixtureDesign(dutData);
        //Post to DFP
        let designData = new FormData();
        designData.append("user", response.data.user);
        designData.append("project", response.data.project);
        designData.append("fixture_design", response.data.pk);
        designData.append("version", response.data.version);
        designData.append("active", true);
        await this.saveDesignFilePackage(designData);

        //Post to DC
        for (let i = 1; i < this.formQuoteNumberOfDuts + 1; i++) {
          await this.postDC({
            user,
            project: projectObj.pk,
            fixture_design: response.data.pk,
            dut_name: this[`formQuoteDutName${i}`],
            dut_details: dut_details[`id_dut${i}`],
          });
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
        await this.removeProject(projectObj.pk);
      }
    },
    async postDC({ user, project, fixture_design, dut_name, dut_details }) {
      let dutData = new FormData();
      dutData.append("name", dut_name);
      dutData.append("pcb_thickness", this.DEFAULT_PCB_THICKNESS);
      dutData.append("user", user);
      dutData.append("project", project);
      dutData.append("fixture_design", fixture_design);
      dutData.append("dut_details", dut_details);
      await this.createDutConfig(dutData);
    },
  },
  mounted() {
    this.$refs.form.validate();
  },
};
</script>
